<template>
	<div id="home">
		<!-- 医咻智会医生PC端 -->
		<menuPage class="menuPage"></menuPage>
		<div class="content">
			<contHeader class="contHeader"></contHeader>

			<router-view class="view"></router-view>
		</div>
	</div>
</template>

<script>
	// import {} from '@/request/api.js';
	import menuPage from '@/components/menuPage.vue';
	import contHeader from '@/components/contHeader.vue';
	export default {
		name: 'home',
		components: {
			menuPage,
			contHeader
		},
		data() {
			return {
				activeIndex: '1',
				avatar: ''
			}
		},
		created() {},
		mounted() {},
		methods: {
			ligin() {
				this.$router.push({
					name: 'login'
				})
			}
		}
	}
</script>
<style lang="scss" scoped>
	#home {
		height: 100vh;
		display: flex;

		.menuPage {
			// height: calc(100% - 20px);
		}

		.content {
			flex: 1;
			display: flex;
			overflow: hidden;
			flex-direction: column;
			// padding: 20px 20px 20px 0;

			.view {
				flex: 1;
				overflow-y: auto;
				background-color: #fff;
				// overflow: hidden;
				// border-radius: 10px;
			}
		}
	}
</style>
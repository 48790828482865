<template>
	<div id="contHeader">
		<div class="header">
			<div class="left">
				<div class="title" v-if="token&& token.type=='Doctor'">
					{{$route.meta.title}}
				</div>
				<el-select v-else v-model="sponsorId" placeholder="立项单位" @change="sponsorSelect">
					<el-option v-for="item in projectSponsor" :key="item.id" :label="item.deptName" :value="item.id">
					</el-option>
				</el-select>
			</div>
			<div class="right">
				<el-popover placement="bottom" width="400" trigger="hover" popper-class="msgPop">
					<div class="info" slot="reference">
						<b v-if="msgList[0]" />
						<i class="el-icon-message-solid" />
					</div>
					<div class="msgBox">
						<template v-for="item in msgList">
							<div class="msg" :key="item.id" @click="viewMsg(item)">
								<div class="left">
									<span class="main">
										{{item.message}}
										<el-tag size="mini" type="warning">未读</el-tag>
									</span>
									<span class="date">
										{{$moment(item.createTime).format('lll') }}
									</span>
								</div>
								<el-button plain size="mini" type="primary">查看</el-button>
							</div>
							<el-divider :key="item.id"></el-divider>
						</template>
						<div class="null" v-if="!msgList[0]">
							暂无新的通知消息
						</div>
					</div>
					<div class="showAll" @click="Notifications">查看更多</div>
				</el-popover>
				<el-dropdown @command="handleCommand" trigger="hover">
					<div class="user">
						<el-image fit="cover" :src="require('../assets/imgs/tx.png')">
							<div slot="error" class="image-slot">
								<i class="el-icon-user-solid"></i>
							</div>
						</el-image>
						<b>{{docInfo.fullName}}</b>
						<i class="el-icon-arrow-down"></i>
					</div>
					<el-dropdown-menu slot="dropdown">
						<el-dropdown-item command="logOut"> 退出登录 </el-dropdown-item>
					</el-dropdown-menu>
				</el-dropdown>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		getDoctorInfo,
		getAssistantInfo,
		getOfficialInfo,
		getNotice,
		checkNotice,
		getOfficialNotice,
		checkNotOfficial,
		getMyMechanism,
		editMechanism,
		officialGetMyMechanism,
		officialEditMechanism
	} from '@/request/api.js';
	export default {
		name: 'contHeader',
		components: {},
		data() {
			return {
				token: '',
				docInfo: {
					userName: '李先生',
					userImage: ''
				},
				projectSponsor: [],
				sponsorId: '',
				nums: '',
				msgList: [],
				timer: null
			}
		},
		created() {
			this.token = JSON.parse(localStorage.getItem('token'));
			this.getUserInfo();
			this.getMyMechanismFun();
		},
		mounted() {},
		methods: {
			sponsorSelect(item) {
				// console.log(item)
				// 修改vueX中的sponsorId 参数
				this.$store.commit('setSponsorId', item);
				if (this.token.type == "ASSISTANT") {
					editMechanism({
						mechanismId: item
					}).then(res => {
						console.log(res)
					})
				} else if (this.token.type == "OFFICIAL") {
					officialEditMechanism({
						mechanismId: item
					}).then(res => {
						console.log(res)
					})
				}

			},
			// 获取立项单位列表
			getMyMechanismFun() {
				// console.log(this.token)
				if (this.token.type == "OFFICIAL") {
					officialGetMyMechanism().then(res => {
						if (res.code == 200) {
							this.projectSponsor = res.data;
						}
					})
				} else if (this.token.type == "ASSISTANT") {
					getMyMechanism().then(res => {
						if (res.code == 200) {
							this.projectSponsor = res.data;
						}
					})
				}

			},

			Notifications() {
				this.$router.push({
					name: 'notifications'
				})
			},

			// 查看消息内容
			viewMsg(item) {
				// console.log(JSON.parse(JSON.stringify(item)));
				// return false;

				localStorage.setItem('projectDetails', JSON.stringify({
					id: item.projectId
				}))

				if (localStorage.getItem('regToken')) {
					return false;
				} else if (this.token.type == 'ASSISTANT') {
					checkNotice({
						noticeId: item.id
					}).then(res => {
						if (res.code == 200) {
							clearTimeout(this.timer);
							this.timer = null;
							this.getNoticeFun();
							if (item.type == "CLAIM" || item.type == "WAIT") {
								this.$router.push({
									name: 'meetdetails',
									query: {
										mId: item.meetingId,
										status: 'WAIT_CLAIM',
										now: 3
									}
								});
							} else if (item.type == "SUCCESS") {
								this.$router.push({
									name: 'meetdetails',
									query: {
										mId: item.meetingId,
										now: 3
									}
								});
							} else if (item.type == "OFFCIAL_ERROR" || item.type == "ERROR" || item.type ==
								"UPLOAD") {
								this.$router.push({
									name: 'uploadFile',
									query: {
										mId: item.meetingId
									}
								});
							};
						}
					})
				} else if (this.token.type == 'OFFICIAL') {
					checkNotOfficial({
						noticeId: item.id
					}).then(res => {
						if (res.code == 200) {
							clearTimeout(this.timer);
							this.timer = null;
							this.getOfficalNotice();
							if (item.type == "UPLOAD" || item.type == "ERROR") {
								this.$router.push({
									name: 'officialUploadFile',
									query: {
										id: item.meetingId,
									}
								});
							}
						}
					})
				}
			},

			// 助教获取消息列表
			getNoticeFun() {
				let data = {
					current: 1,
					size: 5,
					isView: 0
				};
				if (this.token) {
					getNotice(data).then(res => {
						if (res.code == 200) {
							this.msgList = res.data.records;
							// this.timer = setTimeout(() => {
							// 	this.getNoticeFun()
							// }, 15000);
						};
					});
				} else {
					clearTimeout(this.timer)
					this.timer = null;
				};
			},

			// 官员获取消息列表
			getOfficalNotice() {
				let data = {
					current: 1,
					size: 5,
					isView: 0
				};
				if (this.token) {
					getOfficialNotice(data).then(res => {
						if (res.code == 200) {
							this.msgList = res.data.records;
							// this.timer = setTimeout(() => {
							// 	this.getOfficalNotice()
							// }, 15000)
						}
					})
				} else {
					clearTimeout(this.timer)
					this.timer = null;
				};
			},

			handleCommand(e) {
				if (e == 'logOut') {
					this.logOut();
				}
			},
			logOut() {
				let that = this;
				this.$confirm('此操作将退出当前账号的登录状态, 是否继续?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					localStorage.clear();
					that.$router.push({
						name: 'login'
					})
				});
			},

			getUserInfo() {
				if (localStorage.getItem('regToken')) {
					return false;
				} else if (this.token.type == 'Doctor') {
					getDoctorInfo().then(res => {
						if (res.code == 200) {
							this.docInfo = res.data;
						}
					})
				} else if (this.token.type == 'ASSISTANT') {
					getAssistantInfo().then(res => {
						if (res.code == 200) {
							this.docInfo = res.data;
							this.sponsorId = res.data.mechanismId * 1;
							this.getNoticeFun();
						}
					})
				} else if (this.token.type == 'OFFICIAL') {
					getOfficialInfo().then(res => {
						// console.log(res)
						if (res.code == 200) {
							this.docInfo = res.data;
							this.sponsorId = res.data.mechanismId * 1;
							this.getOfficalNotice();
						}
					})
				}
			}
		},
	}
</script>

<style lang="scss" scoped>
	#contHeader {
		.header {
			display: flex;
			font-size: 18px;
			padding: 16px 20px;
			flex-direction: row;
			align-items: center;
			background-color: #fff;
			justify-content: space-between;
			border-bottom: 2px solid #eee;

			.user {
				display: flex;
				cursor: pointer;
				flex-direction: row;
				align-items: center;

				.el-image {
					width: 45px;
					height: 45px;
					border-radius: 50%;
					background-color: #f8f8f8;
					display: flex;
					align-items: center;
					justify-content: center;
				}

				b {
					margin: 0 10px;
					font-weight: normal;
				}
			}

			.right {
				display: flex;
				align-items: center;

				.info {
					margin-right: 20px;
					position: relative;

					b {
						position: absolute;
						right: 0;
						top: 0;
						width: 6px;
						height: 6px;
						border-radius: 50%;
						background-color: #f00;
					}


				}
			}

			.left {
				margin-right: 50px;

				::v-deep .el-select {
					.el-input {}

					.el-input__inner {
						// border: none;
						font-size: 20px;
						color: #333;
						// padding: 0;
						width: auto;
					}
				}
			}
		}
	}
</style>

<style lang="scss">
	.msgPop {
		.msgBox {
			display: flex;
			flex-direction: column;

			.el-divider {
				margin: 0;
			}

			.msg,
			.null {
				display: flex;
				align-items: center;
				padding: 10px 10px;

				.el-button {
					margin-left: 20px;
				}
			}

			.left {
				display: flex;
				flex-direction: column;

			}

			.main {
				font-size: 15px;
				margin-bottom: 6px;
				text-align: justify;

			}

			.date {
				font-size: 12px;
				color: #999;
				display: flex;
				align-items: center;
				justify-content: space-between;

				::v-deep .el-tag {
					margin-left: 10px;
				}
			}

			.null {
				align-items: center;
				justify-content: center;
			}
		}

		.showAll {
			text-align: center;
			border: 1px solid #eee;
			color: #666;
			padding: 8px;
			border-radius: 6px;
			margin: 10px 0 0;
			cursor: pointer;
			transition: all .3s;

			&:hover {
				background-color: #eee;
				color: #000;
			}
		}
	}
</style>